




































import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'portable-fetch';
import 'url-polyfill';
import 'abortcontroller-polyfill';

import Vue from 'vue';
import Component from 'vue-class-component';
import { State, Mutation } from 'vuex-class';

import logger from '@/logger';
import '@/language';
import FlashMessage from '@/components/molecules/FlashMessage.vue';
import Loading from '@/components/atoms/Loading.vue';
import { Idp , LoginParams , AuthToken } from '@/store/types/modules';

import { FlashMessage as FlashMessageState, Message } from '@/store/flashMessage';
import {
  initGoogle,
  isExpiredGoogleToken,
  isGoogleIssuer,
  getGoogleProfile,
} from '@/oidc/google';
import {
  initMicrosoft,
  loginWithMicrosoft,
  isExpiredMicrosoftToken,
  isMicrosoftIssuer,
  getMicrosoftProfile,
} from '@/oidc/microsoft';
import {
  extractTokensFromHash,
  decodeIdToken,
  IdToken,
  validateIdToken,
} from '@/oidc/jwt';
import { createStoreAuthToken } from '@/helpers/authToken';
import { resetAuth } from '@/helpers/auth';

/**
 * getUrlFromQuery gets URL from url param of URL search query
 * @param {string} currentUrlRaw - Current URL
 * @return {string} URL from url param of URL search query
 */
const getUrlFromQuery = (currentUrlRaw: string): string => {
  const currentUrl = new URL(currentUrlRaw);
  if (!currentUrl) {
    return '';
  }

  const encodedPrevUrl = currentUrl.searchParams.get('url');
  if (!encodedPrevUrl) {
    return '';
  }
  const prevUrlRaw = decodeURIComponent(encodedPrevUrl);

  return prevUrlRaw;
};

/**
 * appPath returns the path of first page after login
 * @param {string} currentUrlRaw - Current URL
 * @param {string} prevUrlRaw - Previous URL
 * @return {string} Application path
 */
const appPath = (currentUrlRaw: string, prevUrlRaw?: string): string => {
  const defaultAppPath = '/rooms';

  if (!prevUrlRaw) {
    return defaultAppPath;
  }

  const currentUrl = new URL(currentUrlRaw);
  if (!currentUrl) {
    return defaultAppPath;
  }

  let prevUrl = null;
  try {
    prevUrl = new URL(prevUrlRaw);
  } catch (e) {
    /* pass */
  }
  if (!prevUrl) {
    return defaultAppPath;
  }

  if (prevUrl.origin !== currentUrl.origin) {
    return defaultAppPath;
  }

  const prevPath = `${prevUrl.pathname}${prevUrl.search}`;
  return prevPath;
};

@Component({ components: { FlashMessage, Loading } })
export default class Login extends Vue {
  @State('login') login!: LoginParams;

  @State('idp') idp!: Idp;

  @State('flashMessage') flashMessage!: FlashMessageState;

  @State('authToken') authToken!: AuthToken;

  @Mutation('setLoginParams') setLoginParams!: (l: LoginParams) => void;

  @Mutation('setIdp') setIdp!: (i: Idp) => void;

  @Mutation('setIntentionallyLoggingIn') setIntentionallyLoggingIn!: (intentionallyLoggingIn: boolean) => void;

  @Mutation('addMessage') addFlashMessage!: (m: Message) => void;

  @Mutation('resetMessages') resetFlashMessages!: () => void;

  isGoogleEnabled = false;

  isNotLoggedIn = true;

  redirectingMessage = this.$gettext('Redirecting');

  googleSigninButton = this.$refs.googleSigninButton;

  // googleSigninButton = null;


  async created(): Promise<void> {
    const prevUrlRaw = getUrlFromQuery(window.location.href);
    if (prevUrlRaw) {
      logger.info('Setting login params...');
      this.setLoginParams({ url: prevUrlRaw });
      logger.info('Set login params', this.login);
    }

    if (validateIdToken(this.authToken.token)) {
      this.isNotLoggedIn = false;
      logger.info("Token is defined, navigating to user's page");
      window.location.href = appPath(window.location.href, prevUrlRaw);
    }
  }

  async mounted(): Promise<void> {
    initGoogle(async (response: any) => {
      logger.info('Init Google...');
      logger.info(response);

      this.setIntentionallyLoggingIn(true);
      await this.afterLoginWithGoogle(response);
    }, (e) => {
      logger.error(e);
    });
    logger.info(`App version is ${process.env.VUE_APP_VERSION}`);

    const tokensFromHash = extractTokensFromHash(document.location.hash);


    if (!tokensFromHash.idToken) {
      return;
    }

    const idpIdToken = decodeIdToken<IdToken>(tokensFromHash.idToken);

    if (!idpIdToken.iss) {
      logger.error('Failed to login');
      this.error(this.$gettext('Failed to login'));
      return;
    }

    if (isGoogleIssuer(idpIdToken.iss)) {
      this.isNotLoggedIn = false;
    } else if (isMicrosoftIssuer(idpIdToken.iss)) {
      this.isNotLoggedIn = false;
      await this.afterLoginWithMicrosoft(tokensFromHash.idToken);
    }
  }

  async afterLoginWithGoogle(idToken: string): Promise<void> {
    logger.info('Logged in Google');


    logger.info('Getting ID token from Google...');
    await this.finishRegularSignInWithGoogle(idToken);

    await this.goToAppAfterLogin();
  }

  async finishRegularSignInWithGoogle(credential: string): Promise<void> {
    let error = false;
    if (isExpiredGoogleToken(credential)) {
      logger.error('ID token of Google was expired', this.idp);
      this.error(this.$gettext('ID token of Google was expired'));
      error = true;
    }

    if (error) {
      resetAuth();
      return;
    }

    const newIdp = (await getGoogleProfile(credential)) as Idp;
    logger.info('Got ID token from Google', newIdp);

    logger.info('Setting idp...', newIdp);
    this.setIdp(newIdp);
    logger.info('Set idp', newIdp);
  }

  async goToAppAfterLogin(): Promise<void> {
    const newAppPath = appPath(window.location.href, this.login.url);
    logger.info('Resetting login params...', this.login);
    this.setLoginParams({ url: '' });
    logger.info('Reset login params', this.login);
    await createStoreAuthToken();
    window.location.href = newAppPath;
  }

  onLoginWithMicrosoft(): void {
    const uaa = initMicrosoft();
    logger.info('Logging in Office 365...');
    loginWithMicrosoft(uaa);
  }

  async afterLoginWithMicrosoft(idTokenRaw: string): Promise<void> {
    logger.info('Logged in Office 365');

    logger.info('Getting ID token from Office 365...');

    let error = false;

    if (isExpiredMicrosoftToken(idTokenRaw)) {
      logger.error('ID token of Office 365 was expired');
      this.error(this.$gettext('ID token of Office 365 was expired'));
      error = true;
    }

    if (error) {
      resetAuth();
      return;
    }

    const newIdp = (await getMicrosoftProfile(idTokenRaw)) as Idp;
    logger.info('Got ID token from Office 365');

    logger.info('Setting idp...', newIdp);
    this.setIdp(newIdp);
    logger.info('Set idp', newIdp);

    this.goToAppAfterLogin();
  }

  error(text: string): void {
    this.addMessage({ text, status: 'error' });
  }

  private addMessage(msg: Message): void {
    this.addFlashMessage(msg);
    setTimeout(() => this.resetFlashMessages(), 3000);
  }
}
