/**
 *  Open ID Connect for Google
 *
 * @see https://developers.google.com/identity/sign-in/web/reference
 */
import jwtDecode from 'jwt-decode';
import { isExpiredTime } from './jwt';

export interface GoogleProfile {
  idpName?: 'google';
  idToken?: string;
  expiresAt?: number;
  profileId?: string;
  profileName?: string;
  profileEmail?: string;
  profilePictureUrl?: string;
}

export interface GoogleCredential {
  aud: string;
  azp: string;
  iss: string;
  jti: string;
  email: string;
  family_name: string;
  given_name: string;
  name: string;
  hd: string;
  picture: string;
  sub: string;
  exp: number;
  iat: number;
  nbf: number;
  email_verified: boolean;
}

export const IDP_NAME_GOOGLE = 'google';

export const GOOGLE_SCOPES = 'openid profile email';

export const CLIENT_ID = process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID || '';

type GoogleOnInit = (response: any) => any;
type GoogleOnFailure = (error: any) => any;

export const initGoogle = (onInit: GoogleOnInit, onFailure: GoogleOnFailure): void => {
  try {
    google.accounts.id.initialize({
      client_id: CLIENT_ID,
      callback: async (response: google.accounts.id.CredentialResponse) => {
        // const responsePayload = jwt_decode(response.credential);
 
        onInit(response.credential);
      },
    });
    
    const options: google.accounts.id.GsiButtonConfiguration = { type: 'standard', theme: "outline", size: "large", click_listener: () => onInit };
    const elem = document.getElementById('GoogleSignin');

    if (elem !== null) {
      google.accounts.id.renderButton(
        elem,
        options // customization attributes
      );
    }
    google.accounts.id.prompt((notification) => {
      console.log(notification);
    });
  } catch(e) {
    onFailure(e);
  }
};

export const isGoogleIssuer = (iss: string): boolean => {
  if (!iss) {
    return false;
  }
  return iss.indexOf('accounts.google.com') > -1;
};

export const getCredential = (param: string): GoogleCredential => {
  return jwtDecode(param);
};

export const isExpiredGoogleToken = (param: string): boolean => {
  const credential: GoogleCredential = getCredential(param);
  const expiresAt = credential.exp * 1000;

  return isExpiredTime(expiresAt);
};

export const getGoogleProfile = (param: string): GoogleProfile => {
  const credential: GoogleCredential = getCredential(param);

  const expiresAtSec = credential.exp * 1000;

  return {
    idpName: IDP_NAME_GOOGLE,
    idToken: param,
    expiresAt: expiresAtSec,
    profileId: credential.sub,
    profileName: credential.name,
    profileEmail: credential.email,
    profilePictureUrl: credential.picture,
  };
};
