/**
 * Open ID Connect for Microsoft
 *
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js
 */
import * as msal from 'msal';

import { isExpiredTime, IdToken, decodeIdToken } from './jwt';

const SCOPES = ['openid', 'profile', 'email', 'user.read'];
const AUTHORITY = 'https://login.microsoftonline.com/organizations';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
export const CLIENT_ID = process.env.VUE_APP_MICROSOFT_AUTH_CLIENT_ID || '';

export const IDP_NAME_OFFICE365 = 'microsoft';

export interface MicrosoftProfile {
  idpName?: 'microsoft';
  idToken?: string;
  expiresAt?: number;
  profileId?: string;
  profileName?: string;
  profileEmail?: string;
  profilePictureUrl?: string;
}

export interface MicrosoftIdToken extends IdToken {
  email?: string;
  name?: string;
}

type tokenReceivedCallback = (
  errorDesc: string,
  token: string,
  error: string,
  tokenType: string,
  userState: string,
) => void;

export const initMicrosoft = (): msal.UserAgentApplication => {
  const authOptions = {
    clientId: CLIENT_ID,
    authority: AUTHORITY,
    postLogoutRedirectUri: '', // Disable redirection
    navigateToLoginRequestUrl: false,
  };

  const cacheOptions = {
    cacheLocation: 'localStorage' as msal.CacheLocation,
    storeAuthStateInCookie: true,
  };

  const config = {
    auth: authOptions,
    cache: cacheOptions,
  };

  return new msal.UserAgentApplication(config);
};

export const loginWithMicrosoft = (uaa: msal.UserAgentApplication): void => {
  const emptyAuthCallback = (): void => {};
  uaa.handleRedirectCallback(emptyAuthCallback);
  uaa.loginRedirect({ scopes: SCOPES });
};

export const loginWithMicrosoftSilent = (
  uaa: msal.UserAgentApplication,
): Promise<msal.AuthResponse> => uaa.acquireTokenSilent({ scopes: SCOPES });

export const isExpiredMicrosoftToken = (idTokenRaw: string): boolean => {
  const idToken = decodeIdToken<MicrosoftIdToken>(idTokenRaw);
  const expireAtSec = idToken.exp ? idToken.exp : 0;
  return isExpiredTime(expireAtSec);
};

export const isMicrosoftIssuer = (iss: string): boolean => {
  if (!iss) {
    return false;
  }
  return iss.indexOf('https://login.microsoftonline.com') > -1;
};

export const getMicrosoftGraph = async (accessToken: string): Promise<any> =>
  fetch(GRAPH_ENDPOINT, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(
    (response: Response): Response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw response;
    },
  );

export const getMicrosoftProfile = async (idTokenRaw: string): Promise<MicrosoftProfile> => {
  // let graph;
  // if (idTokenRaw) {
  //  graph = await getMicrosoftGraph(idTokenRaw);
  // }

  const idToken = decodeIdToken<MicrosoftIdToken>(idTokenRaw);
  return {
    idToken: idTokenRaw || '',
    expiresAt: idToken.exp ? idToken.exp : 0,
    idpName: IDP_NAME_OFFICE365,
    profileId: idToken.sub,
    profileName: idToken.name,
    profileEmail: idToken.email,
    profilePictureUrl: '',
  };
};
